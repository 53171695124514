// Generated by Framer (e2fa450)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lEJNkoETw", "Bx9r5rh9z", "wwquFcGhI", "BB7D0bDRj"];

const serializationHash = "framer-eOd3b"

const variantClassNames = {BB7D0bDRj: "framer-v-19gkr1b", Bx9r5rh9z: "framer-v-cv3um4", lEJNkoETw: "framer-v-17kbvhg", wwquFcGhI: "framer-v-ka2wdl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Annual - v1": "lEJNkoETw", "Annual - v2": "wwquFcGhI", "Monthly - v1": "Bx9r5rh9z", "Monthly - v2": "BB7D0bDRj"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, BYkGzs5ev: tap ?? props.BYkGzs5ev, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lEJNkoETw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BYkGzs5ev, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lEJNkoETw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapvmuzjf = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (BYkGzs5ev) {const res = await BYkGzs5ev(...args);
if (res === false) return false;}
setVariant("Bx9r5rh9z")
})

const onTap1c1gq74 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (BYkGzs5ev) {const res = await BYkGzs5ev(...args);
if (res === false) return false;}
setVariant("lEJNkoETw")
})

const onTap152zha7 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (BYkGzs5ev) {const res = await BYkGzs5ev(...args);
if (res === false) return false;}
setVariant("BB7D0bDRj")
})

const onTap1fqhtz8 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (BYkGzs5ev) {const res = await BYkGzs5ev(...args);
if (res === false) return false;}
setVariant("wwquFcGhI")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-17kbvhg", className, classNames)} data-framer-name={"Annual - v1"} data-highlight layoutDependency={layoutDependency} layoutId={"lEJNkoETw"} onTap={onTapvmuzjf} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({BB7D0bDRj: {"data-framer-name": "Monthly - v2", onTap: onTap1fqhtz8}, Bx9r5rh9z: {"data-framer-name": "Monthly - v1", onTap: onTap1c1gq74}, wwquFcGhI: {"data-framer-name": "Annual - v2", onTap: onTap152zha7}}, baseVariant, gestureVariant)}><motion.div className={"framer-3rm5i5"} data-framer-name={"Background"} layoutDependency={layoutDependency} layoutId={"dpuXsM_9Q"} style={{background: "linear-gradient(116deg, rgb(51, 124, 247) 0%, rgb(137, 39, 247) 100%)", backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 64, borderBottomRightRadius: 64, borderTopLeftRadius: 64, borderTopRightRadius: 64}} variants={{BB7D0bDRj: {background: "linear-gradient(116deg, var(--token-29861eb7-eb79-4d31-bb87-2b97d2ed00d3, rgb(140, 140, 140)) 0%, var(--token-29861eb7-eb79-4d31-bb87-2b97d2ed00d3, rgb(140, 140, 140)) 100%)", backgroundColor: "var(--token-29861eb7-eb79-4d31-bb87-2b97d2ed00d3, rgb(140, 140, 140))"}}}/><motion.div className={"framer-1cdu5ad"} data-framer-name={"Circle"} layoutDependency={layoutDependency} layoutId={"wm4fvdjvC"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"}} transformTemplate={transformTemplate1}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eOd3b.framer-c93x9w, .framer-eOd3b .framer-c93x9w { display: block; }", ".framer-eOd3b.framer-17kbvhg { cursor: pointer; height: 32px; overflow: visible; position: relative; width: 51px; }", ".framer-eOd3b .framer-3rm5i5 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-eOd3b .framer-1cdu5ad { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 26px); position: absolute; right: 3px; top: 50%; width: 26px; }", ".framer-eOd3b.framer-v-cv3um4 .framer-1cdu5ad, .framer-eOd3b.framer-v-19gkr1b .framer-1cdu5ad { left: 3px; right: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 51
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Bx9r5rh9z":{"layout":["fixed","fixed"]},"wwquFcGhI":{"layout":["fixed","fixed"]},"BB7D0bDRj":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BYkGzs5ev":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdGHS7IF9i: React.ComponentType<Props> = withCSS(Component, css, "framer-eOd3b") as typeof Component;
export default FramerdGHS7IF9i;

FramerdGHS7IF9i.displayName = "Toggle";

FramerdGHS7IF9i.defaultProps = {height: 32, width: 51};

addPropertyControls(FramerdGHS7IF9i, {variant: {options: ["lEJNkoETw", "Bx9r5rh9z", "wwquFcGhI", "BB7D0bDRj"], optionTitles: ["Annual - v1", "Monthly - v1", "Annual - v2", "Monthly - v2"], title: "Variant", type: ControlType.Enum}, BYkGzs5ev: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerdGHS7IF9i, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})